import * as React from 'react'
import Layout from '../../components/Layout'
import LinkBar from '../../components/LinkBar'
import TopImageHeading from "../../components/TopImageHeading/TopImageHeading";
import BlockLinkPartial from "../../components/BlockLink/BlockLinkPartial";

import {Helmet} from "react-helmet"
import {StaticImage} from "gatsby-plugin-image"
import {Link} from 'gatsby'

//Modular stylesheet
import {blockLinkBox, topImageSectionImage} from "../../components/styles/mainpages.module.css";
import {
    middleCardImage,
    middleCardImages,
    middleCardText,
    middleCardWrapper
} from "../../components/styles/titlepage.module.css";

//Global stylesheet
import "../../components/styles/global.css"

const accommodation = () => {
    return(
        <Layout pageTitle={"transport"}>
            <Helmet>
                <meta charSet="UTF-8"/>
                <meta name="viewport" content="width=device-width, initial-scale=1"/>

                <title>Places to stay in Bangbiangyang | Ministry of Tourism Official Website</title>

                <meta name="author" content="aqq.xyz"/>
                <meta name="description" content="From urban penthouse apartments to a village isolated from civilization, Bangbiangyang has a place for you to call home.
                Whether you're staying for a single night or a thousand nights, view your options at the official site for Tourism Bangbiangyang."/>

                <meta property="og:title" content="Places to stay in Bangbiangyang | Ministry of Tourism Official Website"/>
                <meta property="og:description" content="From urban penthouse apartments to a village isolated from civilization, Bangbiangyang has a place for you to call home.
                Whether you're staying for a single night or a thousand nights, view your options at the official site for Tourism Bangbiangyang."/>
                <meta property="og:url" content="bangbiangyang.aqq.xyz/accommodation"/>
                <meta property="og:site_name" content="bangbiangyang.aqq.xyz"/>

                <meta name="robots" content="noindex"/>

                <link rel="icon" href="../../static/favicon.ico" />
            </Helmet>

            <LinkBar>
                <Link to="/">Home</Link> > <Link to="/accommodation">Where to stay</Link>
            </LinkBar>

            <TopImageHeading title="Accommodation">
                <StaticImage className={topImageSectionImage} src="https://upload.wikimedia.org/wikipedia/commons/9/9f/Lakeside_hotel_%28Unsplash%29.jpg" alt="."/>
            </TopImageHeading>

            <div className="midContentWrapper">
                <div className={middleCardWrapper}>
                    <div className={middleCardText}>
                        <h5 data-sal="slide-up">
                            Long before the advent of modern history, a wandering traveler found solace in a small village near the shore of a local island. As years passed,
                            he went on to sing praises of their hospitality; the very same we uphold today.
                        </h5>
                        <p data-sal="slide-up">
                            From a gleaming resort in the middle of the city to a quiet abode by a forest lake, you'll never be left without a place to
                            stay in Bangbiangyang. Whether you love to be surrounded by people, or if you'd rather find sanctuary in a more secluded space,
                            find just that and more in our accommodation options.
                        </p>

                        <h3 className="genericHeading" data-sal="slide-up">
                            Urban accommodation options
                        </h3>

                        <div className={blockLinkBox}>
                            <BlockLinkPartial title="Yes" destination="./y">
                                Ayayayayayay
                            </BlockLinkPartial>
                            <BlockLinkPartial title="No" destination="./y">
                                ayayayayayya
                            </BlockLinkPartial>
                        </div>
                    </div>

                    <div className={middleCardImage}>
                        <StaticImage className={middleCardImages} src="https://upload.wikimedia.org/wikipedia/commons/b/b8/JR_Hokkaido_Kiha40_near_Jomon_tunnel_20200811_0918_30358.jpg"
                                     alt="." width={3648} height={5472}/>
                    </div>
                </div>
            </div>

        </Layout>
    )
}

export default accommodation